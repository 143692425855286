<template>
  <div class="mod-demo-echarts">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card>
          <div id="J_chartBarBox" class="chart-box"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import {XLABEL} from "@/config/echats.option";

export default {
  props: {
    serveInfo: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartBar: null,
    }
  },
  mounted() {
    const that = this;
    that.$nextTick(() => {
      that.initChartBar()
    })
  },
  activated() {
    // 由于给echart添加了resize事件, 在组件激活时需要重新resize绘画一次, 否则出现空白bug
    if (this.chartBar) {
      this.chartBar.resize()
    }
  },
  methods: {
    // 柱状图
    initChartBar() {
      let infos = JSON.parse(JSON.stringify(this.serveInfo));
      infos.sort((a, b) => {
        return b.serveObjNum - a.serveObjNum;
      })
      infos = infos.slice(0,10)
      const names = infos.map(info => info.uname ? info.uname : info.wechatName ? info.wechatName : '无');
      const serveNumVals = infos.map(info => info.serveNum);
      const serveObjNumVals = infos.map(info => info.serveObjNum);
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['服务次数', '服务人次']
        },

        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: names,
            ...XLABEL
          }
        ],
        yAxis: [
          {
            type: 'log'
          }
        ],
        series: [
          {
            name: '服务次数',
            type: 'bar',
            data: serveNumVals
          },
          {
            name: '服务人次',
            type: 'bar',
            data: serveObjNumVals
          },
        ]
      }
      this.chartBar = echarts.init(document.getElementById('J_chartBarBox'))
      this.chartBar.setOption(option)
      window.addEventListener('resize', () => {
        this.chartBar.resize()
      })
    },
  },
  watch: {
    serveInfo: {
      handler() {
        this.initChartBar();
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.mod-demo-echarts {
  > .el-alert {
    margin-bottom: 10px;
  }

  > .el-row {
    margin-top: -10px;
    margin-bottom: -10px;

    .el-col {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .chart-box {
    min-height: 400px;
  }
}
</style>
